<template>
  <redemption-page
    :funds-selling-list="getFundsSellingList"
    :funds-in-redemption="getActiveRedemptionFundsId"
    @download="download"
    @redemptionRequest="redemptionRequest"
    @download-file="downloadFile"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RedemptionPage from "../../../components/pages/cabinet/Funds/RedemptionPage";
export default {
  name: "RedemptionView",
  components: { RedemptionPage },
  watch: {
    fondChanged() {
      this.GET_FUNDS();
    },
  },
  computed: {
    ...mapGetters("FundsModule", ["getFundsSellingList"]),
    ...mapGetters("Sockets", ["getActiveRedemptionFundsId"]),
    ...mapGetters("AutoUpdateModule", ["fondChanged"]),
  },
  methods: {
    ...mapActions("FundsModule", ["GET_FUNDS"]),
    ...mapActions("DocumentsModule", ["DOWNLOAD_DOCUMENT"]),
    ...mapActions("regModule", ["DOWNLOAD_FILE"]),
    download(data) {
      this.DOWNLOAD_DOCUMENT({
        id: data.id,
        name: `${data.name}.${data.type}`,
      });
    },
    downloadFile(file) {
      this.DOWNLOAD_FILE(file)
    },
    redemptionRequest(fund) {
      this.$router.push(
        `/cabinet/investment/redemption-of-investment-shares/${fund}`
      );
    },
  },
  mounted() {
    this.GET_FUNDS();
  },
};
</script>
