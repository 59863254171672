<template>
    <div class="documents" id="account">
        <div class="form-container">
            <h1>Заявки на погашение</h1>
            <div v-if="fundsSellingList && fundsSellingList.length > 0" class="form-container__form">
<!--                <h3>Заявки в работе</h3>-->
                <rwm-shares-card
                        v-for="card in fundsSellingList"
                        :key="card.id"
                        :title="card.name"
                        :fund-status="card.status_id"
                        :id="card.id"
                        :sales="card.sales"
                        :redemption="true"
                        :active="amount(card) > 0 && fundsInRedemption.some(fund => fund === card.id)"
                        @download="download"
                        @download-file="downloadFile"
                        @redemptionRequest="redemptionRequest"
                />
            </div>
<!--            <div v-if="fundsSellingArchive && fundsSellingArchive.length > 0" class="form-container__form">-->
<!--                <h3>Заявки в архиве</h3>-->
<!--                <rwm-shares-card-->
<!--                        v-for="card in fundsSellingArchive"-->
<!--                        :key="card.id"-->
<!--                        :title="card.name"-->
<!--                        :fund-status="card.status_id"-->
<!--                        :id="card.id"-->
<!--                        :ankets="card.ankets"-->
<!--                        :sales="card.sales"-->
<!--                        :redemption="true"-->
<!--                        @download="download"-->

<!--                />-->
<!--            </div>-->
            <div v-if="!(fundsSellingList && fundsSellingList.length > 0) && !(fundsSellingArchive && fundsSellingArchive.length > 0)">
                <h3>У вас нет заявок на погашение</h3>
            </div>
            <!--            <div class="form-container__info">-->
            <!--                <p class="anketa">-->
            <!--                    Это какой-то информационный текст для всех страниц с карточками. Лица могут быть признаны квалифицированными инвесторами, если они отвечают требованиям, установленным Федеральным законом "О рынке ценных бумаг"и принятыми в соответствии с ним нормативными актами Банка России.-->
            <!--                </p>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    import RwmSharesCard from "../../../RWMSharesCard/RwmSharesCard";

    export default {
        name: 'RedemptionPage',
        components: {RwmSharesCard},
        props: {
            fundsSellingList: Array,
            fundsSellingArchive: Array,
            fundsInRedemption: Array,
        },
        methods: {
            amount(fund) {
                return fund.userInvestShares?.share_count
            },
            download(data) {
                this.$emit('download', data)
            },
            redemptionRequest(fund) {
                this.$emit('redemptionRequest', fund)
            },
            downloadFile(file) {
              this.$emit("download-file", file);
            },
        }
    }
</script>

<style scoped lang="scss">
    .documents {
        & .form-container {
            &__form {
                flex-direction: column;
                & .funds-plate {
                    @media screen and (min-width: 1310px) {
                        flex: 0 1 calc(50% - 22px);
                    }
                    @media screen and (min-width: 1740px) {
                        flex: 0 1 calc(33.3333% - 22px);
                    }
                }
            }
        }
    }
</style>